import axios from "axios";

export const instance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_API_KEY}`
  }
});

export const sigInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_SIG_WEB
});

export const commonInterceptor = config => {
  const token = process.env.NEXT_PUBLIC_API_KEY;
  if (config.headers) config.headers.common["Authorization"] = token;
  return config;
};

export const nextApiInstance = axios.create({
  baseURL: "/api"
});

export const authInterceptor = token => config => {
  if (config.headers) config.headers.common["Authorization"] = token;
  return config;
};

export const postAuth = (username, password) =>
  instance.post("/account/auth", { username, password, store: "santander" });

export const postConsent = origin =>
  instance.post(`/auth/policy-consent`, origin);

export const postDocumentOrder = (orderId, formData) =>
  instance.post(`/order/${orderId}/payment/receipt`, formData);

export const getStateList = () => {
  return new Promise((resolve, reject) => {
    resolve({
      data: [
        { label: "Acre", id: "AC" },
        { label: "Alagoas", id: "AL" },
        { label: "Amapá", id: "AP" },
        { label: "Amazonas", id: "AM" },
        { label: "Bahia", id: "BA" },
        { label: "Ceará", id: "CE" },
        { label: "Distrito Federal", id: "DF" },
        { label: "Espírito Santo", id: "ES" },
        { label: "Goiás", id: "GO" },
        { label: "Maranhão", id: "MA" },
        { label: "Mato Grosso", id: "MT" },
        { label: "Mato Grosso do Sul", id: "MS" },
        { label: "Minas Gerais", id: "MG" },
        { label: "Pará", id: "PA" },
        { label: "Paraíba", id: "PB" },
        { label: "Paraná", id: "PR" },
        { label: "Pernambuco", id: "PE" },
        { label: "Piauí", id: "PI" },
        { label: "Rio de Janeiro", id: "RJ" },
        { label: "Rio Grande do Norte", id: "RN" },
        { label: "Rio Grande do Sul", id: "RS" },
        { label: "Rondônia", id: "RO" },
        { label: "Roraima", id: "RR" },
        { label: "Santa Catarina", id: "SC" },
        { label: "São Paulo", id: "SP" },
        { label: "Sergipe", id: "SE" },
        { label: "Tocantins", id: "TO" }
      ]
    });
  });
};

export const getOrders = token =>
  instance.get(
    "order/seller/santander",
    token ? { headers: { Authorization: `Bearer ${token}` } } : undefined
  );

export const getVehicle = sku => instance.get(`/vehicle/santander/${sku}`);

export const postSearchSimilarVehicles = (sku, brand) =>
  instance.post(`/search/santander`, {
    from: 0,
    size: 4,
    query: {
      bool: {
        should: {
          match: { "brand.id": brand }
        },
        must_not: {
          term: {
            sku: sku
          }
        },
        must: {
          term: {
            "group.slug": "santander"
          }
        }
      }
    }
  });

export const getStoreById = id =>
  instance.get(`/scheduling/store/${id}`).then(res => res.data);

export const getOffers = vehicleId =>
  instance.get(`/order/store/santander/product/${vehicleId}/best-offer`);

export const putOrderCustomer = (orderId, customerId) =>
  instance.put(`/order/${orderId}/customer/${customerId}`);

export const getOrder = orderId => instance.get(`/order/${orderId}`);

export const verifyPasswordRecoveryToken = token =>
  instance.get(`/registration/password-recovery/${token}`);

export const putPasswordChange = (token, password) =>
  instance.put(`/registration/password-recovery/${token}/change-password`, {
    password
  });

export const postPasswordRecovery = email =>
  instance.post(`/registration/password-recovery`, { email });

export const getAddress = zipcode =>
  axios.get(`https://viacep.com.br/ws/${zipcode}/json/`);

export const getUserReferral = () =>
  instance.get("/registration/referral-contact");

export const postFinancing = data =>
  instance.post("/finance/financing/santander/pre-analysis", data);

export const getAvailableDates = storeId =>
  instance.get(`/scheduling/${storeId}/dates`).then(res => res.data);

export const getAvailableTimes = (storeId, chosenDate) =>
  instance
    .get(`/scheduling/${storeId}/times/${chosenDate}`)
    .then(res => res.data);

export const postScheduleVehiclePickup = data =>
  instance.post(`/scheduling/withdrawal`, data);

export const putScheduleVehiclePickup = data =>
  instance
    .put(`/scheduling/withdrawal/${data.sku}`, data)
    .then(res => res.data);

import React from "react";
import Link from "next/link";

const LinkAdapter = ({ href, target, children }) => {
  if (href && !target) {
    return <Link href={href}>{children}</Link>;
  }

  return <>{children}</>;
};

export default LinkAdapter;

import React from "react";
import { Footer as LoopFooter } from "@loop-brasil/material";
import LinkAdapter from "components/Header/LinkAdapter";

const Footer = () => {
  return (
    <LoopFooter
      linkWrapper={LinkAdapter}
      menu={[
        {
          name: "Loop",
          links: [
            {
              label: "Termos e Condições de Uso",
              href: "/conteudo/termos-e-condicoes",
              target: "_blank"
            },
            {
              label: "Política de privacidade",
              href: "/conteudo/politica-de-privacidade",
              target: "_blank"
            },
            {
              label: "LGPD",
              href: "/conteudo/lgpd",
              target: "_blank"
            }
          ]
        },
        {
          name: "Dúvidas",
          links: [
            {
              label: "Atendimento",
              href: "https://app.pipefy.com/public/form/0tfIXkX5",
              target: "_blank"
            },
            {
              label: "Canal Aberto Webmotors",
              href: "https://aloetica.com.br/webmotors",
              target: "_blank"
            }
          ]
        }
      ]}
    />
  );
};

export default Footer;

import React from "react";
import { signOut } from "next-auth/react";
import { makeStyles } from "@material-ui/core";
import { Header as HeaderLoop } from "@loop-brasil/material";
import { useAuth } from "hooks/useAuth";
import LinkAdapter from "./LinkAdapter";

const useStyles = makeStyles(() => ({
  root: {
    "& nav": {
      padding: "0"
    }
  }
}));

export const Header = () => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();

  return (
    <div className={classes.root}>
      <HeaderLoop
        menu={[
          {
            href: "/",
            label: "Veículos"
          },
          {
            href: "/conteudo/termos-e-condicoes",
            label: "Termos e Condições"
          },

          { href: "/conteudo/como-funciona", label: "Como funciona" }
        ]}
        authMenu={{
          authenticated: [
            { label: "Editar meus dados", href: "/cadastro/alterar" },
            { label: "Minhas ofertas", href: "/minhas-ofertas" },
            {
              label: "Sair",
              onClick: () => signOut({ callbackUrl: "/" })
            }
          ],
          unAuthenticated: [
            { label: "Cadastro", href: "/pre-cadastro" },
            { label: "Login", href: "/login" }
          ]
        }}
        authenticated={isAuthenticated}
        linkWrapper={LinkAdapter}
      />
    </div>
  );
};

export default Header;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: "24px",
    lineHeight: "36px",
    marginBottom: "40px",
    fontWeight: theme.typography.fontWeightMedium,

    "@media (min-width: 960px)": {
      fontSize: "32px",
      lineHeight: "52px"
    }
  }
}));

const Subtitle = ({ id, children, variant, className }) => (
  <Typography
    id={id}
    component="h2"
    variant={variant || "h2"}
    className={`${useStyles().root} ${className}`}
  >
    {children}
  </Typography>
);

export default Subtitle;

import { instance } from "api";
import { useSession as useNextAuthSession } from "next-auth/react";

export const useAuth = () => {
  const session = useNextAuthSession();

  const token = session?.data?.token;
  const isAuthenticated = session?.status === "authenticated";
  const isLoading = session?.status === "loading";

  if (isAuthenticated && token) {
    instance.defaults.headers["Authorization"] = `Bearer ${token}`;
  }

  return {
    session: session?.data,
    isAuthenticated,
    isLoading
  };
};
